<template>
  <div class="stat">
    <h3>今日出刀 {{ total }} / 90</h3>
    <div style="width: 90%; max-width: 60em">
      <el-table
        :data="challenList"
        stripe
        border
        style="width: 100%"
        cell-style="padding: 0"
        header-cell-style="padding: 0; height: 4em;"
      >
        <el-table-column
          prop="eid"
          label="编号"
          align="right"
          sortable
          min-width="6"
        />
        <el-table-column
          prop="uid"
          label="头像"
          align="center"
          sortable
          width="61"
        >
          <template #default="scope">
            <div style="height: 50px; padding: 5px 0">
              <el-avatar
                :src="`http://q1.qlogo.cn/g?b=qq&nk=${scope.row.uid}&s=140`"
                :alt="scope.row.uid"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="昵称"
          align="center"
          min-width="10"
          v-if="windowWidth > 500"
        />
        <el-table-column
          prop="time"
          label="记录时间"
          align="center"
          sortable
          min-width="10"
          v-if="windowWidth > 650"
        />
        <el-table-column
          prop="round"
          label="周目"
          align="center"
          sortable
          min-width="4"
        />
        <el-table-column
          prop="boss"
          label="王"
          align="center"
          sortable
          min-width="4"
        />
        <el-table-column
          prop="dmg"
          label="伤害"
          align="right"
          sortable
          min-width="12"
        />
        <el-table-column
          prop="flag"
          label="类型"
          align="center"
          min-width="8"
        />
      </el-table>
    </div>
  </div>
</template>

<script>
import DataService from '@/services/DataService'

export default {
  data() {
    return {
      total: 0,
      challenList: [],
      windowWidth: window.innerWidth,
    }
  },
  created() {
    this.$emit('changeGid', this.$route.params.gid)
    DataService.getChallengeList(this.$route.params.gid)
      .then((resp) => {
        this.total = resp.data.total
        this.challenList = resp.data.challenges
        this.challenList.reverse()
      })
      .catch((error) => {
        console.error(error)
      })
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResize)
  },
  unmounted() {
    window.removeEventListener('resize', this.onWindowResize)
  },
  methods: {
    onWindowResize() {
      this.windowWidth = window.innerWidth
    },
  },
  props: ['gid'],
  emits: ['changeGid'],
}
</script>

<style scoped>
.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
